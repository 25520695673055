<template>
    <div
        data-scroll
        :data-scroll-id="name"
        :class="{ dark: !lightMode }"
        class="wine-making-slider"
    >
        <text-wipe
            id="reputation-header"
            :color="shaderColor"
            :header="headerTitle"
        />
        <div :style="{ height: itemHeight }" class="item-list">
            <div
                v-for="(item, index) in sliderItems"
                :class="{ active: activeItemIndex === index }"
                :key="index"
                class="item"
            >
                <div class="image-container">
                    <div :style="{ left: navBarPosition }" class="list-navbar">
                        <swiper class="only-mobile" :options="swiperOptions">
                            <swiper-slide
                                class="navbar-item"
                                :class="{
                                    active: activeItemIndex === index
                                }"
                                @click.native="activeItemIndex = index"
                                :key="index"
                                v-for="(item, index) in sliderItems"
                            >
                                <span class="title">{{ item.name }}</span>
                                <div class="dot"></div>
                                <div class="line"></div>
                            </swiper-slide>
                        </swiper>
                        <div
                            class="navbar-item only-desktop"
                            @click="activeItemIndex = index"
                            :key="index"
                            v-for="(item, index) in sliderItems"
                            :class="{
                                active: activeItemIndex === index
                            }"
                        >
                            <span class="title">{{ item.name }}</span>
                            <div class="dot"></div>
                            <div class="line"></div>
                        </div>
                    </div>
                    <div class="image-overflow">
                        <img :src="item.image" class="image" alt="" />
                    </div>
                </div>
                <div class="content">
                    <h2 :style="{ color: item.color }" class="title">
                        {{ item.name }}
                    </h2>
                    <div class="description" v-html="item.description"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imagesLoaded from "imagesloaded";
import { mapGetters } from "vuex";
import first from "lodash/first";
import textWipe from "@/components/custom/textWipe";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            activeItemIndex: 0,
            itemHeight: 0,
            navBarWidth: 0,
            scrollTimeOut: null,
            swiperOptions: {
                init: true,
                slidesPerView: "auto",
                spaceBetween: 30,
                slidesOffsetAfter: 200,
                freeMode: true
            }
        };
    },
    components: {
        textWipe
    },
    computed: {
        ...mapGetters({
            scroll: "scroll/getScroll",
            device: "resize/geDevice"
        }),
        navBarPosition() {
            let padding = 50;
            if (this.device.size < 1366) {
                padding = 30;
            }
            return `-${this.navBarWidth + padding}px`;
        },
        sliderItems() {
            return this.data?.list?.map(item => ({
                background: item.background_color,
                image: item.image?.[0]?.devices?.[this.device?.type],
                name: first(item?.content?.tabs)?.title,
                description: first(item?.content?.tabs)?.content,
                color: item.color1
            }));
        },
        headerTitle() {
            return this.config?.header;
        },
        shaderColor() {
            return this.lightMode ? [1, 1, 1] : [0.039, 0.078, 0.11];
        }
    },
    methods: {
        setHeight() {
            this.$nextTick(() => {
                if (this.scrollTimeOut) clearTimeout(this.scrollTimeOut);
                this.itemHeight =
                    document
                        .querySelector(
                            ".wine-making-slider .item-list .item.active"
                        )
                        ?.getBoundingClientRect()?.height + "px";
                this.navBarWidth = document
                    .querySelector(".wine-making-slider .list-navbar")
                    ?.getBoundingClientRect()?.width;
                this.scrollTimeOut = setTimeout(() => {
                    this.scroll?.update();
                }, 300);
            });
        },
        resize() {
            this.setHeight();
        },
        resizeHandler() {
            window.addEventListener("resize", this.resize, false);
        }
    },
    watch: {
        //d
        activeItemIndex: {
            handler: function() {
                this.setHeight();
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            imagesLoaded(
                document.querySelector(".wine-making-slider .item-list"),
                () => {
                    this.setHeight();
                }
            );
            this.resizeHandler();
        });
    },
    beforeDestroy() {
        if (this.scrollTimeOut) clearTimeout(this.scrollTimeOut);
        window.removeEventListener("resize", this.resizeHandler);
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.only-mobile {
    display: none;
}
.only-desktop {
    display: block;
}
@media only screen and (max-width: 499px) {
    .only-mobile {
        display: block;
    }
    .only-desktop {
        display: none;
    }
}
.wine-making-slider /deep/ {
    margin-top: 144px;
    padding: 0 230px;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media only screen and (max-width: 1650px) and (min-width: 1366px) {
        padding: 0 193px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        padding: 0 145px;
    }
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        margin-top: 89px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding: 0 47px;
        margin-top: 89px;
    }
    @media only screen and (max-width: 767px) {
        padding: 0 16px;
        margin-top: 55px;
    }

    .content {
        .header {
            color: #0a141c;
            text-align: center;
        }
    }

    .list-navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        grid-gap: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 12;
        opacity: 0;
        width: 190px;
        @media (max-width: 1679px) {
            transform: translateY(-50%) translateX(40px);
        }
        @media (max-width: 1365px) {
            width: 120px;
            transform: translateY(-50%) translateX(10px);
        }
        @media only screen and (max-width: 1023px) {
            position: relative;
            flex-direction: row;
            top: unset;
            left: 50% !important;
            transform: translateX(-50%);
            width: fit-content;
            margin-bottom: 30px;
        }
        @media only screen and (max-width: 499px) {
            margin-bottom: 20px;
            transform: translateX(0%);
            left: 0 !important;
        }

        .navbar-item {
            display: flex;
            align-items: center;
            grid-gap: 10px;
            position: relative;
            cursor: pointer;
            width: fit-content;

            @media only screen and (max-width: 499px) {
                width: max-content !important;
            }

            &.active {
                .title {
                    color: #e5b676;
                }

                .dot,
                .line {
                    opacity: 1;
                }
            }

            .title {
                transition: all 0.4s $ease-out;
                color: #0a141c;
                letter-spacing: 1.3px;
                text-align: right;
                @media only screen and (max-width: 1024px) and (min-width: 768px) {
                    font-size: 14px;
                    letter-spacing: 1.15px;
                }
            }

            .dot {
                @include circle(10);
                position: absolute;
                background: #e5b676;
                opacity: 0;
                right: -20px;
                transition: all 0.4s $ease-out;
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    right: -15px;
                }
                @media only screen and (max-width: 1023px) {
                    display: none;
                }
            }

            .line {
                position: absolute;
                height: 1px;
                width: 60px;
                right: -90px;
                background: #e5b676;
                opacity: 0;
                transition: all 0.4s $ease-out;
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    right: -80px;
                }
                @media only screen and (max-width: 1023px) {
                    width: 100%;
                    right: 0;
                    left: 0;
                    top: 100%;
                }
            }
        }
    }

    .item-list {
        position: relative;
        width: 100%;

        .item {
            pointer-events: none;
            position: absolute;
            width: 100%;

            &.active {
                pointer-events: auto;

                .image-overflow {
                    transition: all 0.5s 0.5s $ease-out;
                }

                .image-overflow,
                .title,
                .description {
                    opacity: 1 !important;
                    transform: translateY(0) scale(1);
                }

                .title {
                    transition: all 0.5s 0.5s $ease-out !important;
                    @media (max-width: 1023px) {
                        white-space: nowrap;
                    }
                }

                .description {
                    transition: all 0.5s 0.6s $ease-out, color 0.4s $ease-out !important;
                }

                .list-navbar {
                    opacity: 1 !important;
                }
            }
        }

        .header-title {
            text-align: center !important;
        }

        .image-container {
            width: 100%;
            margin-top: 55px;
            position: relative;
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                margin-top: 35px;
            }

            .image-overflow {
                overflow: hidden;
                height: 610px;
                opacity: 0;
                position: relative;
                transform: scale(0.95);
                transition: all 0.5s $ease-out;
                @media only screen and (max-width: 1650px) and (min-width: 1366px) {
                    height: 407px;
                }
                @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                    height: 377px;
                }
                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    height: 330px;
                }
                @media only screen and (max-width: 767px) {
                    height: 186px;
                }

                &:hover {
                    .image {
                        transform: scale(1.2);
                    }
                }

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.6s $ease-out;
                }
            }
        }

        .content {
            margin-top: 34px;
            padding: 0 150px;
            @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                padding: 0 30px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                padding: 0 10px;
                margin-top: 21px;
            }
            @media only screen and (max-width: 767px) {
                padding: 0;
                margin-top: 34px;
            }

            .title {
                font-size: 55px;
                color: #740b43;
                text-align: left;
                opacity: 0;
                font-weight: normal;
                transition: all 0.5s $ease-out;
                transform: translateY(30px);
                @media only screen and (max-width: 1650px) and (min-width: 768px) {
                    font-size: 34px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 21px;
                }
            }

            .description {
                margin-top: 13px;
                color: #0a141c;
                text-align: left;
                opacity: 0;
                font-size: 21px;
                font-family: "Noto-Sans", "Noto-Sans-georgian";
                transition: all 0.5s $ease-out, color 0.4s $ease-out;
                transform: translateY(30px);

                p,
                span,
                h2,
                div {
                    font-family: "Noto-Sans", "Noto-Sans-georgian";
                }
            }
        }
    }
}

.wine-making-slider.dark /deep/ {
    .content {
        .header {
            color: white;
        }
    }

    .navbar-item {
        .title {
            color: white;
        }
    }

    .item-list {
        .content {
            .description {
                color: white;
            }
        }
    }
}
</style>
